import React, { Component, Suspense } from "react";
import PropTypes from "prop-types";
import mapboxgl from "mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import styled from "styled-components";
import Div100vh from "react-div-100vh";

import { connect } from "react-redux";

import { getConfig } from "../redux/actions/config/getConfig";
import { getSpotLayer, receivedSpotLayerFeatures } from "../redux/actions/spots";
import { spotPopupOpen, spotPopupClose } from "../redux/actions/spotPopup";
import { geolocateUpdate, geolocateEnd } from "../redux/actions/geolocate";

import MapControlFullscreenStyle from "../plugins/BUGA/components/MapControlFullscreenStyle";
import MapControlZoomStyle from "../plugins/BUGA/components/MapControlZoomStyle";
import MapControlLocateStyle from "../plugins/BUGA/components/MapControlLocateStyle";
import MapControlCameraStyle from "../plugins/BUGA/components/MapControlCameraStyle";
import MapControlListStyle from "../plugins/BUGA/components/MapControlListStyle";
import MapControlCompassStyle from "../plugins/BUGA/components/MapControlCompassStyle";

// const BUGAMarker = React.lazy(() => import("../plugins/BUGA/BUGAMarker"));
import UEMarker from "../plugins/uebermaps/Markers/UEMarker";
import BUGAMarker from "../plugins/BUGA/BUGAMarker";
import BUGAPhotoMarker from "../plugins/BUGA/BUGAPhotoMarker";

// const SpotPopup = React.lazy(() => import("../plugins/uebermaps/SpotPopup/SpotPopup"));
const SpotPopup = React.lazy(() => import("../plugins/BUGA/SpotPopup"));
const SpotPopupPhoto = React.lazy(() => import("../plugins/BUGA/SpotPopupPhoto"));
// const SpotList = React.lazy(() => import("../plugins/uebermaps/SpotList/SpotList"));
const SpotList = React.lazy(() => import("../plugins/BUGA/SpotList"));
const PhotoUpload = React.lazy(() => import("../plugins/BUGA/PhotoUpload"));

const MapContainer = styled.div.attrs({
  className: "mapContainer"
})`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .mapboxgl-ctrl-top-left--custom {
    position: absolute;
    top: 0;
    left: 0;

    .mapboxgl-ctrl-group {
      background-color: unset;
      box-shadow: unset;
      width: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
      margin-top: 0.33rem;

      .cameraButton {
        ${MapControlCameraStyle()}
      }

      .listButton {
        ${MapControlListStyle()}
      }
    }
  }
`;

const MapboxWrapper = styled.div.attrs({
  className: "mapboxWrapper"
})`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;

  /* #TODO: Add styles for buga temporarily to every map. Should go into config. */
  .markerPhoto {
    &.markerPhotoArrow:after,
    &.markerPhotoArrow:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &.markerPhotoArrow:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #ffffff;
      border-width: 10px;
      margin-left: -10px;
    }
    &.markerPhotoArrow:before {
      border-color: rgba(194, 225, 245, 0);
      border-top-color: rgba(159, 159, 159, 0.5);
      border-width: 16px;
      margin-left: -16px;
    }
  }

  .mapboxgl-ctrl-top-right {
    .mapboxgl-ctrl-group {
      background-color: unset;
      box-shadow: unset;
      width: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
      margin-top: 0.33rem;
    }
    .mapboxgl-ctrl-fullscreen.mapboxgl-ctrl-icon {
      ${MapControlFullscreenStyle()}
    }
  }
  .mapboxgl-ctrl-bottom-right {
    .mapboxgl-ctrl-group {
      background-color: unset;
      box-shadow: unset;
      width: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
      margin-top: 0.33rem;
    }
    .mapboxgl-ctrl-zoom-in.mapboxgl-ctrl-icon {
      ${MapControlZoomStyle("in")}
    }
    .mapboxgl-ctrl-zoom-out.mapboxgl-ctrl-icon {
      ${MapControlZoomStyle("out")}
    }
    .mapboxgl-ctrl-geolocate.mapboxgl-ctrl-icon {
      ${MapControlLocateStyle()}
    }
    .mapboxgl-ctrl-compass.mapboxgl-ctrl-icon {
      ${MapControlCompassStyle()}
    }
  }
`;

mapboxgl.accessToken = "pk.eyJ1IjoiMXRtLXNvbHV0aW9ucyIsImEiOiJjanc2M2s2YTkwN2F5NDFvNHpiYTd4Nmh4In0.mkalDMbbBjfDIwdP_CSfmg";

class Map extends Component {
  static propTypes = {
    data: PropTypes.array
  };

  MARKER_TYPES = {
    BUGAMarker: BUGAMarker,
    BUGAPhotoMarker: BUGAPhotoMarker
  };

  spotLayerHandler = { one: "two" };

  mapContainerRef = React.createRef();

  // config = {
  //   spotsURL: null,
  //   mapOptions: {
  //     styleId: null,
  //     centerLat: null,
  //     centerLng: null,
  //     zoom: null,
  //     pitch: null,
  //     bearing: null
  //   }
  // };

  map = null;
  markers = {};
  updateMarkersTimer = null;

  constructor(props) {
    super(props);

    const opts = this.props.match.params;
    console.log("parsed query string: ", opts);

    let spotsFinishedLoadingAction = null;
    if (opts.spotLayer !== undefined && opts.spotId !== undefined) {
      spotsFinishedLoadingAction = {
        type: "openSpot",
        spotLayer: opts.spotLayer,
        spotId: opts.spotId
      };
    }

    this.state = {
      spotsFinishedLoadingAction,
      currentCameraFlyToPlaceIndex: 0,
      cameraTimer: null,
      userInteractedWithMap: false,
      cameraActive: false,
      listVisible: false
      // options: {
      //   styleId: opts.style_id || "cjr67oyp70tok2srwa3vmjed1",
      //   centerLat: opts.c_lat || 49.14519119332243,
      //   centerLng: opts.c_lng || 9.20785968904761,
      //   zoom: opts.zoom || 15.5,
      //   pitch: opts.pitch || 60
      // }
    };
  }

  onCloseList = e => {
    console.log("onCloseList");
    this.setState({
      listVisible: false
    });
  };

  onShowList = e => {
    this.setState({
      listVisible: true
    });
  };

  onToggleList = e => {
    this.setState({
      listVisible: !this.state.listVisible
    });
  };

  onListFeatureClick = feature => {
    this.onCloseList();
    this.setState({
      cameraActive: false
    });

    this.map.flyTo({
      bearing: 0,
      pitch: 0,
      speed: 1.2,
      zoom: 17,
      center: feature.geometry.coordinates
    });
  };

  onCameraButtonPressed = e => {
    console.log("onCameraButtonPressed");
    if (this.state.cameraActive === true) {
      clearTimeout(this.state.cameraTimer);
      this.setState({
        cameraActive: false,
        userInteractedWithMap: false
      });
      this.map.jumpTo({
        bearing: 0,
        pitch: 0
      });
    } else {
      this.startCamera();
    }
  };

  startCamera = () => {
    const { data, selectedFeature, onSpotPopupOpen } = this.props;

    this.setState({ cameraActive: true });

    console.log("startCamera", data);
    clearTimeout(this.state.cameraTimer);

    if (this.state.userInteractedWithMap === true) {
      return;
    }

    let allFeatures = [];
    for (const spotLayer of data) {
      allFeatures = [...allFeatures, ...spotLayer.featureCollection.features];
    }

    if (allFeatures.length === 0) {
      return;
    }

    if (this.state.currentCameraFlyToPlaceIndex > allFeatures.length - 1) {
      this.setState({
        currentCameraFlyToPlaceIndex: 0
      });
    }

    const flyToSpot = allFeatures[this.state.currentCameraFlyToPlaceIndex];
    let pitch = Math.random() * 60;
    let offset = [0, 0];
    let bearing = 0 + (Math.random() - 0.5) * 90;
    let zoom = 16 + Math.random() * 3;
    // if spot popup is open center the point above the spot popup and set pitch to 90 deg.
    if (selectedFeature !== null) {
      // spot popup covers 70% of screen, so shift spot up to make it visible
      const mapHeight = this.map.getCanvas().height;
      offset = [0, -(mapHeight * 0.5) * 0.35];
      pitch = 0;
      bearing = 0;
      zoom = 17;
      onSpotPopupOpen(flyToSpot);
    }

    this.map.flyTo({
      bearing,
      center: flyToSpot.geometry.coordinates,
      zoom,
      pitch,
      offset,
      speed: 0.5
    });

    this.map.once("moveend", () => {
      this.setState({
        cameraTimer: setTimeout(() => {
          console.log("Fly to next place");
          this.setState({
            currentCameraFlyToPlaceIndex: this.state.currentCameraFlyToPlaceIndex + 1
          });
          if (this.state.cameraActive === true) {
            this.startCamera();
          }
        }, 3000)
      });

      // this.state.cameraTimer = setTimeout(() => {
      //   console.log("Fly to next place");
      //   this.setState({
      //     currentCameraFlyToPlaceIndex: this.state.currentCameraFlyToPlaceIndex + 1
      //   });
      //   if (this.state.cameraActive === true) {
      //     this.startCamera();
      //   }
      // }, 3000);
    });
  };

  addLayersToMap = (map, layers = []) => {
    console.log("|-----#DEBUG | C:Map|M:addLayersToMap | layers -----:", layers);
    const styleLayers = map.getStyle().layers;

    for (const layer of layers) {
      console.log(layer);

      for (const styleLayer of styleLayers) {
        if (layer.insertAfterLayerId) {
          if (styleLayer.id === layer.insertAfterLayerId) {
            map.addLayer(layer, layer.insertAfterLayerId);
            break;
          }
        } else {
          map.addLayer(layer);
          break;
        }
      }
    }
  };

  addSpotLayer = spotLayer => {
    console.log("--- adding spotLayer", spotLayer);
    // image overlay
    // this.map.addSource("buga-image", {
    //   type: "image",
    //   url: "https://www.mapbox.com/mapbox-gl-js/assets/radar.gif",
    //   coordinates: [
    //     [-80.425, 46.437],
    //     [-71.516, 46.437],
    //     [-71.516, 37.936],
    //     [-80.425, 37.936]
    //   ]
    // });
    // this.map.addLayer({
    //   id: "image-overlay",
    //   source: "buga-image",
    //   type: "raster",
    //   paint: {
    //     "raster-opacity": 0.85
    //   }
    // });

    // this.map.addLayer({
    //   id: "raster-layer",
    //   type: "raster",
    //   source: {
    //     type: "raster",
    //     tiles: [
    //       "https://api.mapbox.com/v4/geocha.bd8jzfjk/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiMXRtLXNvbHV0aW9ucyIsImEiOiJjanc2M2s2YTkwN2F5NDFvNHpiYTd4Nmh4In0.mkalDMbbBjfDIwdP_CSfmg"
    //     ],
    //     tileSize: 256
    //   },
    //   minzoom: 0,
    //   maxzoom: 22
    // });

    const name = spotLayer.name
      ? spotLayer.name
      : Math.random()
          .toString(36)
          .substring(7);

    const featureCollection = spotLayer.featureCollection;

    if (this.map.getSource(name) !== undefined) {
      this.map.getSource(name).setData(featureCollection);
      this.startUpdateMarkersTimer(1000);
      return;
    }
    // add spots
    this.map.addSource(name, {
      type: "geojson",
      data: featureCollection,
      cluster: true,
      clusterMaxZoom: 16, // Max zoom to cluster points on
      clusterRadius: 200 // Radius of each cluster when clustering points (defaults to 50)
    });

    // add clusters circles
    this.map.addLayer({
      id: `clusters`,
      type: "circle",
      source: name,
      filter: ["has", "point_count"],
      paint: {
        // Use step expressions (https://www.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        // with three steps to implement three types of circles:
        //   * Blue, 20px circles when point count is less than 100
        //   * Yellow, 30px circles when point count is between 100 and 750
        //   * Pink, 40px circles when point count is greater than or equal to 750
        "circle-stroke-width": 5,
        "circle-stroke-color": "#FFF",
        "circle-color": ["step", ["get", "point_count"], "#8B8B8B", 20, "#8B8B8B"],
        "circle-radius": ["step", ["get", "point_count"], 30, 20, 30]
      }
    });

    // add clusters labels
    this.map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: name,
      filter: ["has", "point_count"],
      paint: {
        "text-color": "#ffffff"
      },
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["Arial Unicode MS Bold", "DIN Offc Pro Medium"],
        "text-size": 18
      }
    });

    // unclustered points
    this.map.addLayer({
      id: "unclustered-point",
      layerName: name,
      type: "circle",
      source: name,
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-opacity": 0,
        "circle-color": "#FF0000",
        "circle-translate": [0, 0],
        "circle-translate-anchor": "map",
        // make circles larger as the user zooms from z12 to z22
        "circle-radius": 30,
        // "circle-radius": {
        //   base: 1.75,
        //   type: "exponential",
        //   stops: [[12, 30], [22, 30]]
        // },
        "circle-stroke-opacity": 0,
        "circle-stroke-width": 0,
        "circle-stroke-color": "#f00"
      }
    });

    // unclustered points labels
    // this.map.addLayer({
    //   id: "unclustered-point-text",
    //   type: "symbol",
    //   source: name,
    //   filter: ["!", ["has", "point_count"]],
    //   paint: {
    //     "text-opacity": 0,
    //     "text-color": "#ffffff"
    //   },
    //   layout: {
    //     "icon-image": "{picture_url_thumb}",
    //     "text-field": "Text: {picture_url_thumb}",
    //     "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    //     "text-size": 12
    //   }
    // });

    // Inspect a cluster on click
    this.map.on("click", "clusters", e => {
      var features = this.map.queryRenderedFeatures(e.point, {
        layers: ["clusters"]
      });
      var clusterId = features[0].properties.cluster_id;
      this.map.getSource(name).getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return;
        }

        this.map.easeTo({
          center: features[0].geometry.coordinates,
          zoom: zoom
        });
      });
    });

    // When a click event occurs on a feature in the places layer, open a popup at the
    // location of the feature, with description HTML from its properties.

    // this.map.on("click", "building", e => {
    //   for (const feature of e.features) {
    //     console.log("--- click building", feature);
    //   }
    // });

    // deprecated
    this.map.on("click2", "unclustered-point", e => {
      console.log("--- clicked on unclustered-point", e);
      var coordinates = e.features[0].geometry.coordinates.slice();
      var description = JSON.stringify(e.features[0].properties);

      this.props.onSpotPopupOpen(e.features[0]);
      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      // new mapboxgl.Popup()
      //   .setLngLat(coordinates)
      //   .setHTML(description)
      //   .addTo(this.map);
    });

    // Hove states
    this.map.on("mouseenter", "clusters", () => {
      this.map.getCanvas().style.cursor = "pointer";
    });
    this.map.on("mouseleave", "clusters", () => {
      this.map.getCanvas().style.cursor = "";
    });

    this.startUpdateMarkersTimer(1000);
  };

  startUpdateMarkersTimer = (msecs = 200) => {
    // console.log("--- startUpdateMarkersTimer", this.updateMarkersTimer);
    clearTimeout(this.updateMarkersTimer);
    this.updateMarkersTimer = setTimeout(this.updateMarkers, msecs);
  };

  getMarkerForFeature = feature => {
    let marker = null;
    if (feature.source !== null && feature.source !== undefined && this.props.config !== null) {
      for (const spotLayer of this.props.config.spotLayers) {
        if (spotLayer.marker !== undefined) {
          marker = this.MARKER_TYPES[spotLayer.marker.name];
        }
      }
    }

    if (marker === null || marker === undefined) {
      marker = UEMarker;
    }

    // this.props.onSpotPopupOpen(e.features[0]);

    const newMarker = marker.fromFeature(feature, this.props.onSpotPopupOpen);

    return newMarker;
  };

  updateMarkers = e => {
    // console.log("--- updateMarkers");
    // let currentPoint = e.point;
    // console.log("updateMarkers for point", currentPoint);

    // var features = this.map.queryRenderedFeatures(currentPoint, { layers: ['unclustered-point'] });
    const layerName = "unclustered-point";
    if (this.map === undefined || this.map.getLayer(layerName) === undefined) {
      // console.log("--- updateMarkers: undefined");
      return;
    }

    var features = this.map.queryRenderedFeatures({ layers: [layerName] });
    if (features === undefined) {
      // console.log("features undefined");
      return;
    } else if (features.length === 0) {
      // console.log("--- features length === 0");
      // return;
    }
    // console.log(`--- features ${features.length}`, features);

    let visibleSpotIds = {};
    for (const feature of features) {
      const spotId = feature.properties.id;
      visibleSpotIds[`${spotId}`] = true;
    }

    for (const spotId in this.markers) {
      if (!(spotId in visibleSpotIds)) {
        // console.log("spot should be removed", spotId);
        this.markers[spotId].remove();
        delete this.markers[spotId];
      }
    }

    for (const feature of features) {
      const spotId = feature.properties.id;
      const marker = this.markers[`${spotId}`];

      // add new markers
      if (marker === undefined) {
        // console.log("marker not present in markers", feature);
        // create a HTML element for each feature
        // var el = document.createElement("div");
        // el.className = "marker";
        // let pictureUrl = feature.properties.picture_url;
        // if (pictureUrl === undefined || pictureUrl === null || pictureUrl === "") {
        //   pictureUrl = "https://mdn.mozillademos.org/files/6457/mdn_logo_only_color.png";
        // }
        // el.setAttribute("style", `background-image: url('${pictureUrl}');`);

        // // make a marker for each feature and add to the map
        // let m = new mapboxgl.Marker(el);
        // m.setLngLat(feature.geometry.coordinates);
        // if (this.props.config !== null) {
        //   console.log("--- we have a config", feature.source);
        // }
        const m = this.getMarkerForFeature(feature);
        m.addTo(this.map);
        this.markers[`${spotId}`] = m;
      } else {
        // console.log("marker already present", marker);
      }
    }
    // var clusterId = features[0].properties.cluster_id;
    // var point_count = features[0].properties.point_count;
    // var clusterSource = this.map.getSource('mainSpots');

    // // Get Next level cluster Children
    // //
    // clusterSource.getClusterChildren(clusterId, (err, aFeatures) => {
    //   console.log('getClusterChildren', err, aFeatures);
    // });

    // // Get all points under a cluster
    // clusterSource.getClusterLeaves(clusterId, point_count, 0, (err, aFeatures) => {
    //   console.log('getClusterLeaves', err, aFeatures);
    // })
  };

  getChangedSpotLayers = (oldData, newData) => {
    let changedSpotLayers = [];
    const oldSpotLayerInfo = {};

    return [...newData];

    // // get names of existings spotLayers and their feature count
    // for (const oldSpotLayer of oldData) {
    //   // console.log(`--- oldData ${oldSpotLayer.name} ${oldSpotLayer.featureCollection.features.length}`);
    //   oldSpotLayerInfo[oldSpotLayer.name] = oldSpotLayer.featureCollection.features.length;
    // }

    // for (const newSpotLayer of newData) {
    //   // add new spotLayer if not present in old data or new feature count is higher than old feature count
    //   // console.log(`--- newData ${newSpotLayer.name} ${newSpotLayer.featureCollection.features.length}`);
    //   if (newSpotLayer.name in oldSpotLayerInfo) {
    //     // console.log("-- spotLayer is present", newSpotLayer.name);
    //     // console.log(`-- spotLayer ${oldSpotLayerInfo[newSpotLayer.name]} : ${newSpotLayer.featureCollection.features.length}`);
    //     if (newSpotLayer.featureCollection.features.length > oldSpotLayerInfo[newSpotLayer.name]) {
    //       changedSpotLayers = [...changedSpotLayers, newSpotLayer];
    //     }
    //   } else {
    //     changedSpotLayers = [...changedSpotLayers, newSpotLayer];
    //   }
    // }
    // return changedSpotLayers;
  };

  componentDidUpdate(prevProps) {
    console.log("--- componentDidUpdate");
    // Typical usage (don't forget to compare props):
    if (this.props.config !== prevProps.config) {
      this.initializeMap();
      this.initializePlugins();
    }

    // was a spot added to data
    const changedSpotLayers = this.getChangedSpotLayers(prevProps.data, this.props.data);
    // console.log("-- changedSpotLayers.length", changedSpotLayers.length);
    for (const spotLayer of changedSpotLayers) {
      this.addSpotLayer(spotLayer);
    }
    if (this.state.spotsFinishedLoadingAction !== null) {
      this.performSpotsFinishedLoadingAction(this.state.spotsFinishedLoadingAction);
    }
  }

  findSpotInSpotLayer = (spotId, spotLayerName) => {
    for (const spotLayer of this.props.data) {
      if (spotLayer.name === spotLayerName) {
        for (const feature of spotLayer.featureCollection.features) {
          if (String(feature.properties.id) === String(spotId)) {
            return feature;
          }
        }
      }
    }

    return null;
  };

  performSpotsFinishedLoadingAction = action => {
    const spot = this.findSpotInSpotLayer(action.spotId, action.spotLayer);
    if (spot !== null) {
      switch (action.type) {
        case "openSpot":
          console.log(`---the spot`, spot);
          this.props.onSpotPopupOpen(spot);
          this.setState({
            spotsFinishedLoadingAction: null
          });
          break;

        default:
          break;
      }
    }
  };

  // static getDerivedStateFromProps(nextProps, prevState){
  //   console.log("getDerivedStateFromProps", nextProps);
  //   if(nextProps.path!==prevState.path){
  //     return {path : nextProps.path};
  //   }
  //   else return null;
  // }

  // componentWillReceiveProps_off(nextProps) {
  //   console.log("componentWillReceiveProps", nextProps);
  //   // if (this.props !== nextProps) {
  //   // }

  //   if (this.props.data === null) {
  //     console.log("componentWillReceiveProps: data === null", nextProps);
  //     if (nextProps.data !== null) {
  //       this.addSpotLayer(nextProps.data);
  //     }
  //     if (nextProps.config !== null) {
  //       // this.initializeMap();
  //     }
  //   } else {
  //     console.log("componentWillReceiveProps: data !== null", nextProps);
  //   }
  // }

  loadSpotLayerHandler = async spotLayer => {
    const url = spotLayer.layerHandlerURL;
    if (url !== null && url !== undefined) {
      console.log("--- loading layerHandlerURL", url);
      return import(`../plugins/${url}`).then(myModule => {
        console.log("loaded layerHandlerURL", myModule);
        console.log("--- this.spotLayerHandler", url);
        this.spotLayerHandler[url] = new myModule.default(spotLayer);
      });
    }
  };

  fetchSpotLayer = async spotLayer => {
    // hast spotlayer a specific handler?
    let layerHandler = null; // <- set this to default uebermaps layer handler
    const url = spotLayer.layerHandlerURL;
    if (url !== undefined) {
      // is handler already loaded?
      if (url in this.spotLayerHandler) {
        layerHandler = this.spotLayerHandler[url];
      } else {
        // load layer handler
        await this.loadSpotLayerHandler(spotLayer);
        layerHandler = this.spotLayerHandler[url];
      }
    }

    // get must return an object containing a {"spotLayer", "meta", "data"}
    // where "data" is an array of features;
    layerHandler.get(this.gotNewSpotLayerFeatures);
  };

  gotNewSpotLayerFeatures = (spotLayer, features) => {
    // console.log("--- here is the new data", features);
    this.props.onReceivedSpotLayerFeatures(spotLayer, features);
  };

  componentDidMount() {
    this.props.getConfig();
  }

  initializePlugins = () => {
    // const { config } = this.props;
  };

  initializeMap = () => {
    const { onGeolocateUpdate, onGeolocateEnd, config } = this.props;

    this.map = new mapboxgl.Map({
      attributionControl: false,
      container: this.mapContainerRef.current,
      style: `mapbox://styles/1tm-solutions/${config.mapOptions.styleId}`, // use mapbox://styles/mcloud79/cjr687fzu3a2z2slf2vztmtfl if building below overlay
      center: [config.mapOptions.centerLng, config.mapOptions.centerLat],
      zoom: config.mapOptions.zoom,
      minZoom: config.mapOptions.minZoom,
      maxZoom: config.mapOptions.maxZoom,
      pitch: config.mapOptions.pitch
    }).addControl(
      new mapboxgl.AttributionControl({
        compact: true
      })
    );

    const scale = new mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: "imperial"
    });
    this.map.addControl(scale);

    scale.setUnit("metric");

    let language = new MapboxLanguage();
    this.map.addControl(language);

    this.map.on("moveend", e => {
      // console.log("moveend", this.map.getCenter());
      this.startUpdateMarkersTimer();
    });

    this.map.on("touchend", e => {
      this.startUpdateMarkersTimer();
    });

    // this.map.on("dragend", e => {
    //   console.log("dragend");
    //   this.updateMarkers(e);
    // });

    this.map.on("zoomend", e => {
      this.startUpdateMarkersTimer();
      console.log("--- zoom", this.map.getZoom());
    });

    this.map.on("resize", e => {
      this.startUpdateMarkersTimer();
    });

    this.map.on("rotateend", e => {
      this.startUpdateMarkersTimer();
    });

    this.map.on("pitchend", e => {
      this.startUpdateMarkersTimer();
    });

    // this.map.on("click", e => {
    //   console.log("click");
    //   e.preventDefault;
    //   this.onSpotPopupClose();
    // });

    // this.map.on("render", e => {
    //   console.log("render");
    //   this.updateMarkers(e);
    // });

    this.map.on("load", () => {
      if (config !== null) {
        config.spotLayers.forEach(spotLayer => {
          this.fetchSpotLayer(spotLayer);
        });

        this.addLayersToMap(this.map, config.layers);
      }

      // Add fullscreen control
      this.map.addControl(new mapboxgl.FullscreenControl());

      // Add geolocate control to the map.
      const geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      });
      this.map.addControl(geolocate, "bottom-right");
      this.map.addControl(new mapboxgl.NavigationControl(), "bottom-right");

      geolocate.on("geolocate", e => {
        onGeolocateUpdate(e);
      });

      geolocate.on("trackuserlocationstart", e => {});

      geolocate.on("trackuserlocationend", e => {
        onGeolocateEnd(e);
      });
    });
  };

  render() {
    const { config, data, selectedFeature, geolocatePosition, onSpotPopupClose } = this.props;

    let spotList = null;
    if (data !== null && data.length > 0 && this.state.listVisible === true) {
      let allFeatures = [];
      for (const spotLayer of data) {
        allFeatures = [...allFeatures, ...spotLayer.featureCollection.features];
      }
      spotList = (
        <SpotList features={allFeatures} onFeatureClick={this.onListFeatureClick} onClose={this.onCloseList} />
      );
    }

    let map = null;
    if (config !== null) {
      map = <MapboxWrapper ref={this.mapContainerRef} />;
    }

    let plugins = [];
    if (config !== null && config.plugins) {
      for (const plugin of config.plugins) {
        plugins.push(React.createElement(eval(plugin.name), { key: plugin.name, plugin }));
      }
    }

    let spotPopup = null;
    if (selectedFeature !== null) {
      const PopupComponentName = config.spotLayers[0].onSelectedFeaturePlugin || "SpotPopup";
      spotPopup = React.createElement(eval(PopupComponentName), {
        selectedFeature,
        onSpotPopupClose,
        geolocatePosition
      });
    }

    return (
      <Div100vh>
        <MapContainer>
          {map}

          <div className="mapboxgl-ctrl-top-left--custom">
            <div className="mapboxgl-ctrl-group">
              <button className="cameraButton bg-white ma0" onClick={this.onCameraButtonPressed}>
                <span className="oi" data-glyph={this.state.cameraActive ? "media-stop" : "media-play"} />
              </button>
              <button className="listButton bg-white ma0" onClick={this.onToggleList}>
                <span className="oi" data-glyph={this.state.listVisible ? "list" : "list"} />
              </button>
            </div>
          </div>

          <Suspense fallback={<div>Loading...</div>}>
            {spotPopup}
            {spotList}
            {plugins}
          </Suspense>
        </MapContainer>
      </Div100vh>
    );
  }
}

function mapStateToProps(state) {
  return {
    config: state.config.data,
    data: state.spots.data,
    selectedFeature: state.spotPopup.selectedFeature,
    geolocatePosition: state.geolocate.position
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getConfig: () => {
      if (ownProps.match.params.configId !== undefined) {
        console.log("mapDispatchToProps: getConfig", ownProps.match.params.configId);
        dispatch(getConfig(ownProps.match.params.configId));
      }
    },
    onReceivedSpotLayerFeatures: (spotLayer, features) => {
      dispatch(receivedSpotLayerFeatures(spotLayer, features));
    },
    onGeolocateUpdate: e => {
      const position = { coords: e.coords, timestamp: e.timestamp };

      console.log("geolocate", position);
      dispatch(geolocateUpdate(position));
    },
    onGeolocateEnd: e => {
      console.log("geolocate end", e);
      // dispatch(geolocateEnd(e));
    },
    onSpotPopupOpen: feature => {
      console.log("clicked feature", feature);
      const f = {
        properties: {
          ...feature.properties
        },
        geometry: {
          ...feature.geometry
        }
      };
      dispatch(spotPopupOpen(f));
    },
    onSpotPopupClose: () => {
      dispatch(spotPopupClose());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Map);
