import mapboxgl from "mapbox-gl";

class BUGAMarker {
  static fromFeature(feature, onClick) {
    // console.log("marker not present in markers", feature);
    // create a HTML element for each feature
    var el = document.createElement("div");
    el.className = "buga-marker";
    let pictureUrl = feature.properties.picture_url;
    if (pictureUrl === undefined || pictureUrl === null || pictureUrl === "") {
      pictureUrl = "https://mdn.mozillademos.org/files/6457/mdn_logo_only_color.png";
    }
    // el.setAttribute("style", `background-image: url('${pictureUrl}');`);
    
    const markerWidth = 50;
    const markerHeight = 50;
    el.setAttribute(
      "style",
      `
      background-image: url('${pictureUrl}');
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: transparent;
      width: ${markerWidth}px;
      height: ${markerHeight}px;
      cursor: pointer;
    `
    );

    el.addEventListener('click', () => { 
        onClick(feature);
      }
    ); 

    // make a marker for each feature and add to the map
    let m = new mapboxgl.Marker(el, {
      offset: [0, -markerHeight/2]
    });
    m.setLngLat(feature.geometry.coordinates);
    return m;
  }
}

export default BUGAMarker;
  