import { createGlobalStyle } from "styled-components";

import AkkuratTTF from "../assets/fonts/Akkurat.ttf";
import AkkuratEOT from "../assets/fonts/Akkurat.eot";
import AkkuratWOFF2 from "../assets/fonts/Akkurat.woff2";
import AkkuratWOFF from "../assets/fonts/Akkurat.woff";
import AkkuratSVG from "../assets/fonts/Akkurat.svg";

import AkkuratLightTTF from "../assets/fonts/AkkuratLight.ttf";
import AkkuratLightEOT from "../assets/fonts/AkkuratLight.eot";
import AkkuratLightWOFF2 from "../assets/fonts/AkkuratLight.woff2";
import AkkuratLightWOFF from "../assets/fonts/AkkuratLight.woff";
import AkkuratLightSVG from "../assets/fonts/AkkuratLight.svg";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Akkurat';
    src: url(${AkkuratEOT});
    src: url(${AkkuratEOT}?#iefix) format('embedded-opentype'),
      url(${AkkuratWOFF2}) format('woff2'),
      url(${AkkuratWOFF}) format('woff'),
      url(${AkkuratTTF}) format('truetype'),
      url(${AkkuratSVG}#Akkurat) format('svg');
    font-weight: normal;
    font-style: normal;
  }


  @font-face {
    font-family: 'Akkurat';
    src: url(${AkkuratLightEOT});
    src: url(${AkkuratLightEOT}?#iefix) format('embedded-opentype'),
      url(${AkkuratLightWOFF2}) format('woff2'),
      url(${AkkuratLightWOFF}) format('woff'),
      url(${AkkuratLightTTF}) format('truetype'),
      url(${AkkuratLightSVG}#Akkurat) format('svg');
    font-weight: 300;
    font-style: normal;
  }

  body{
    font-family: 'Akkurat', sans-serif;
  }

  .form-control:focus{
    outline: nonet !important;
    border-style: inherit !important;
    box-shadow: none !important;
  }
  .btn:focus, button:focus{
    box-shadow: none !important;
    outline: none !important;
  }

  a, a:active, a:hover, a:focus, a:visited, a:hover{
    text-decoration: none;
    outline: 0;
  }
  h1, h2, h3, h4, h5, h6{
    font-weight: normal;
  }


  :focus { outline: none; }

  .no-textshadow select {
    color: #C8D600;
  }

  select:focus{
    outline:none; 
  }

  select:-moz-focusring {
    outline:none !important; 
    border-style:none !important;
    color: transparent !important;
    text-shadow: 0 0 0 #C8D600;
  }

  select::-ms-expand {
    display: none;
  }

`;

export const THEME = {};
