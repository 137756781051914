
export const baseUrl = "http://localhost:5100/api/v2";
// export const baseUrl = "https://uebermaps.com/api/v2";

export const token = () => {
  return "FAdqvrntixgPrMyvxEeA";
}



export const basicHeaders = (opts={}) => {
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }

  if (opts.no_token === undefined || opts._no_token === false) {
    headers = {
      ...headers,
    }
    if (token() !== null) {
      headers = {
        ...headers,
        'X-AUTH-TOKEN': token()
      }
    }
  }
  return headers;
//   return new Headers(headers);
}


// GET
export function get(url, params={}, opts={}) {
  if (url.includes("http")) {
    url = new URL(url);
  } else {
    url = new URL(`${baseUrl}/${url}`);
  }
  
  for (let key in params) {
    url.searchParams.append(key, params[key]);
  }
  
  const data = {
    method: 'GET',
    headers: basicHeaders(opts)
  };
  
  return fetch(url, data);
}
