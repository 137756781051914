import { GET_SPOTS_REQUEST, GET_SPOTS_SUCCESS, GET_SPOTS_FAILURE } from "../actions/spots";

const Meta = params => {
  return {
    limit: 100,
    count: 100,
    isFetching: false,
    invalidate: false,
    ...params
  };
};

const defaultState = {
  meta: Meta(),
  data: []
};

// const getExampleData = featureCollection => {
//   // return data;
//   const titles = ["Rosenbeet", "Treffpunkt Baden-Württemberg", "Treffpunkt Baden-Württemberg"];

//   const texts = [
//     "Das schönste Beet im Park.",
//     "Innovativ, zukunftsorientiert, facettenreich: Am Fruchtschuppenareal präsentiert sich das Land mit seinen Behörden und Einrichtungen in jeweils zweiwöchig wechselnden Ausstellungen mit Themen wie Nachhalitgkeit und Mobilität.",
//     "Als Stückguthalle der Deutschen Bahn im Fruchtschuppenareal hat die ABX-Halle längst ausgedient. Während der BUGA wird das Gebäude als Blumenhalle genutzt und entführt seine Besucher in florale Welten, die Geschichten erzählen. Insgesamt 23 Blumenschauen finden hier statt. Jede erzählt eine andere Geschichte. Mit prachtvollen Stauden, farbenfrohen Beet- und Balkonpflanzen, blühenden Gehölzen, edlen Rhododendren, Orchideen, Bonsais und Schnittblumen in Kombination mit Licht und Klang sowie transparenten Stoffen werden für die Besucher wunderschöne Erzählungen inszeniert. Ein Teil der Pflanzen, die in der Blumenhalle gezeigt werden, kann auf dem Gärtnermarkt erworben werden. Er befindet sich zwischen den Blumenhallenbereichen."
//   ];

//   const newFeatures = featureCollection.features.map((feature, index) => {
//     return {
//       ...feature,
//       properties: {
//         ...feature.properties,
//         title: titles[index % 3],
//         description: texts[index % 3]
//       }
//     };
//   });

//   return {
//     ...featureCollection,
//     features: newFeatures
//   };
// };

export const spotsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_SPOTS_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          errors: null,
          isFetching: true
        }
      };
    case GET_SPOTS_SUCCESS:
      return {
        ...state,
        meta: {
          ...action.payload.meta,
          isFetching: false
        },
        data: [
          ...action.payload.data,
        ]
      };
    case GET_SPOTS_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          errors: action.payload.errors
        }
      };
    default:
      return state;
  }
};

// export default spotsReducer;
