import mapboxgl from "mapbox-gl";
import BUGAPhotoMarkerBackground from "./assets/images/BUGAPhotoMarkerBackground.png";

class BUGAPhotoMarker {
  static fromFeature(feature, onClick) {
    // console.log("marker not present in markers", feature);
    // create a HTML element for each feature
    const markerElement = document.createElement("div");
    markerElement.className = "markerPhoto markerPhotoArrow";

    const markerWidth = 100;
    const markerHeight = 66;
    markerElement.setAttribute(
      "style",
      `
      padding: 4px;
      text-align: center;
      background-size: cover;
      border-radius: 8px;

      cursor: pointer;
      background-clip: padding-box;
      height: ${markerHeight}px;
      width: ${markerWidth}px;
      border: 4px solid rgba(159,159,159,0.5);
      
      background-color: #FFFFFF;
      box-shadow: 0 4px 2px 0 rgba(0,0,0,0.14);
    `
    );

    let pictureUrl = feature.properties.picture_url_thumb_keep_ratio;
    if (pictureUrl === undefined || pictureUrl === null || pictureUrl === "") {
      pictureUrl = "https://mdn.mozillademos.org/files/6457/mdn_logo_only_color.png";
    }
    // test portrait image
    // pictureUrl = "https://images.unsplash.com/photo-1417325384643-aac51acc9e5d?q=75&fm=jpg&w=400&fit=max";

    const markerPhotoBlur = document.createElement("div");
    markerPhotoBlur.className = "markerPhotoBlur";
    markerPhotoBlur.setAttribute(
      "style",
      `
      position:absolute;
      top:4px;
      right:4px;
      bottom:4px;
      left:4px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-filter: blur(2px);
      -moz-filter: blur(2px);
      -o-filter: blur(2px);
      -ms-filter: blur(2px);
      filter: blur(2px);
      filter: alpha(opacity=40); 
      opacity: 0.4; 
      -moz-opacity: 0.4;
      background-image: url(${pictureUrl});
    `
    );
    markerElement.appendChild(markerPhotoBlur);

    const markerPhotoImage = document.createElement("div");
    markerPhotoImage.className = "markerPhotoImage";
    markerPhotoImage.setAttribute(
      "style",
      `
      position:absolute;
      top:4px;
      right:4px;
      bottom:4px;
      left:4px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(${pictureUrl});
    `
    );
    markerElement.appendChild(markerPhotoImage);

    markerElement.addEventListener('click', () => { 
        onClick(feature);
      }
    ); 
    // markerElement.setAttribute("style", `background-image: url('${pictureUrl}');`);

    // make a marker for each feature and add to the map
    const m = new mapboxgl.Marker(markerElement, {
      offset: [0, -markerHeight/2]
    });
    m.setLngLat(feature.geometry.coordinates);
    return m;
  }
}

export default BUGAPhotoMarker;
