import { css } from "styled-components";

const MapControlButtonStyle = (icon = null, size = "default") => css`
  box-sizing: border-box;
  height: 45px;
  width: 45px;
  color: rgb(92, 161, 57);
  border: 4px solid rgba(92, 161, 57, 0.29);
  background-color: #ffffff;
  border-radius: 50%;

  ${icon &&
    css`
      background-image: url(${icon});
    `}
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 50%;

  height: ${size === "large" ? "70px" : "45px"};
  width: ${size === "large" ? "70px" : "45px"};
`;

export default MapControlButtonStyle;
