import { SPOT_POPUP_OPEN, SPOT_POPUP_CLOSE } from "../actions/spotPopup";

const defaultState = {
  selectedFeature: null
};

export const spotPopupReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SPOT_POPUP_OPEN:
      return {
        ...state,
        selectedFeature: action.payload.feature
      };
    case SPOT_POPUP_CLOSE:
      return {
        ...state,
        selectedFeature: null
      };
    default:
      return state;
  }
};

// export default spotsReducer;
