import { css } from "styled-components";

import MapControlZoomInIcon from "../assets/images/zoom-plus.svg";
import MapControlZoomOutIcon from "../assets/images/zoom-minus.svg";
import MapControlButtonStyle from "./MapControlButtonStyle";

const MapControlZoomStyle = type => css`
  ${type === "in" ? MapControlButtonStyle(MapControlZoomInIcon) : MapControlButtonStyle(MapControlZoomOutIcon)};
`;

export default MapControlZoomStyle;
