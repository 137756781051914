import { css } from "styled-components";

import MapControlButtonStyle from "./MapControlButtonStyle";

import MapLocateIcon from "../assets/images/locate.svg";

const MapControlLocateStyle = () => css`
  ${MapControlButtonStyle(MapLocateIcon, "large")}
`;

export default MapControlLocateStyle;
