import { get } from "../../api/api";
import { AddFeaturesToCollection, SpotsToGeoJSON } from "../../helpers/convert";

export const GET_SPOTS_REQUEST = "GET_SPOTS_REQUEST";
export const GET_SPOTS_SUCCESS = "GET_SPOTS_SUCCESS";
export const GET_SPOTS_FAILURE = "GET_SPOTS_FAILURE";

export const request = (resetPagination = false) => {
  return {
    type: GET_SPOTS_REQUEST,
    payload: {
      code: 200,
      resetPagination,
      timestamp: Date.now()
    }
  };
};

export const success = response => {
  console.log("--- redux action success", response);
  return {
    type: GET_SPOTS_SUCCESS,
    payload: {
      code: 200,
      meta: response.meta,
      data: response.data,
      timestamp: Date.now()
    }
  };
};

export const failure = (code, errors) => {
  return {
    type: GET_SPOTS_FAILURE,
    payload: {
      code,
      errors,
      timestamp: Date.now()
    }
  };
};


export function receivedSpotLayerFeatures(spotLayer, features) {
  return function(dispatch, getState) {
    let existingSpotLayer = null;
    let currentData = [...getState().spots.data];
    for (const layer of currentData) {
      if (layer.name === spotLayer.name) {
        existingSpotLayer = layer;
        existingSpotLayer.featureCollection = AddFeaturesToCollection(features, existingSpotLayer.featureCollection);
        break;
      }
    }

    if (existingSpotLayer === null) {
      currentData = [
        ...currentData,
        {
          ...spotLayer,
          featureCollection: AddFeaturesToCollection(features)
        }
      ]
    }

    const res = {
      meta: {},
      data: currentData
    }
    dispatch(success(res));
  }
}

export function getSpotLayer(spotLayer) {
  return function(dispatch, getState) {
    // If the url of the spotLayer is not a fully valid url, then assume its a relative path inside the app to a json file and reference it by adding the hostname url:
    const url = spotLayer.url.includes("http")
      ? spotLayer.url
      : window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "") +
        spotLayer.url;
    console.log("url: ", url);

    get(url, {}, { no_token: true })
      .then(function(response) {
        return response.json();
      })
      .then(function(json) {
        console.log("starting getSpotsAPI", json);
        let res = spotLayer.type === "file" ? json : json;

        if (spotLayer.type === "uebermaps") {
          if (res.meta.code === 200) {
            res = {
              meta: res.meta,
              spotLayer,
              featureCollection: AddFeaturesToCollection(SpotsToGeoJSON(res.data))
            };
            dispatch(success(res));
          } else {
            dispatch(failure(res.meta.code, res.meta.errors));
          }
        } else if (spotLayer.type === "file") {
          const collection = AddFeaturesToCollection(json);
          console.log("--- collection", collection);
          res = {
            meta: {
              code: 200
            },
            spotLayer,
            featureCollection: collection
          };
          dispatch(success(res));
        } else {
          res = {
            meta: {
              code: 200
            },
            spotLayer,
            featureCollection: json
          };
          dispatch(success(res));
        }
        // return res;
      })
      .catch(function(ex) {
        console.log("parsing failed", ex);
        dispatch(failure(400, [{ attribute: null, message: "Something went wrong", id: -1 }]));
        // return ex;
      });
  };
}
